import React, { useCallback, useState, useRef } from 'react'
import { frog, object2Map, getType } from '@/services/frog'
import useInserted from '@/utils/useInserted'
import useScrollComment from '@/utils/useScrollComment'
import { JD_OFFICIAL_S2_LINK, TAOBAO_OFFICIAL_S2_LINK } from '@/constants/index'

import PicImg from './assets/pic.png'
import './Banner.less'

export default function Banner({ windowLg }: { windowLg: boolean }) {
  const buyButtonRef = useRef<any>(null)
  const insertedHandler = useCallback((ratio: number) => {
    const el = document.getElementById('navbar-sub')
    if (ratio === 0 && el) {
      el?.classList.add('sticky')
      el?.classList.remove('visibility-0')
    } else {
      el?.classList.remove('sticky')
      el?.classList.add('visibility-0')
    }
  }, [])

  useInserted({
    target: buyButtonRef,
    handler: insertedHandler,
  })

  const { handleClickComment: handleClickComment1, commentIndex: index1 } =
    useScrollComment({
      id: 'certificate',
      windowLg,
    })

  const { handleClickComment: handleClickComment2, commentIndex: index2 } =
    useScrollComment({
      id: 'eink',
      windowLg,
    })

  const { handleClickComment: handleClickComment3, commentIndex: index3 } =
    useScrollComment({
      id: 'pen',
      windowLg,
    })

  function onClickJD() {
    frog('/click/megrezOfficialPage/function/buy', {
      keyValues: object2Map({
        type: getType(),
        buyType: 'jingdong',
      }),
    })
    window.open(JD_OFFICIAL_S2_LINK)
  }

  function onClickTB() {
    frog('/click/megrezOfficialPage/function/buy', {
      keyValues: object2Map({
        type: getType(),
        buyType: 'tianmao',
      }),
    })
    window.open(TAOBAO_OFFICIAL_S2_LINK)
  }

  return (
    <>
      {windowLg ? (
        <section className="bn-root-lg">
          <div className="bn-flex">
            <div className="bn-left">
              <img src={PicImg} alt="小猿学练机" />
            </div>
            <div className="bn-right">
              <div className="bn-wrap">
                <div className="bn-title">
                  <p>小猿学练机</p>
                  <p>不仅能学还能练</p>
                </div>
                <p className="bn-desc">
                  <span>墨水屏学习平板领导者</span>
                  <sup
                    onClick={handleClickComment1}
                    style={{ cursor: 'pointer' }}
                  >
                    {index1}
                  </sup>
                </p>
                <p className="bn-info">
                  <span>
                    10.3英寸类纸护眼墨水屏
                    <sup
                      onClick={handleClickComment2}
                      style={{ cursor: 'pointer' }}
                    >
                      {index2}
                    </sup>
                  </span>
                  <span>|</span>
                  <span>
                    Wacom青少年专属电磁笔
                    <sup
                      onClick={handleClickComment3}
                      style={{ cursor: 'pointer' }}
                    >
                      {index3}
                    </sup>
                  </span>
                </p>
                <p className="bn-info">
                  <span>「以练促学」精准学系统</span>
                  <span>|</span>
                  <span>全场景个性化自主学</span>
                </p>
                <p className="bn-price">
                  <span className="bn-price-num">￥3899</span>
                  <span className="bn-price-desc">￥4299</span>
                </p>
                <div className="bn-btn-flex" ref={buyButtonRef}>
                  <button onClick={onClickJD}>京东官方旗舰店</button>
                  <button onClick={onClickTB}>天猫官方旗舰店</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="bn-root-sm">
          <div className="bn-root-wrap">
            <div className="bn-title">
              <p>小猿学练机</p>
              <p>不仅能学还能练</p>
            </div>

            <p className="bn-desc">
              <span>墨水屏学习平板领导者</span>
              <sup onClick={handleClickComment1} style={{ cursor: 'pointer' }}>
                {index1}
              </sup>
            </p>
          </div>

          <p className="bn-info">
            <span>
              10.3英寸类纸护眼墨水屏
              <sup onClick={handleClickComment2} style={{ cursor: 'pointer' }}>
                {index2}
              </sup>
            </span>
            <span>|</span>
            <span>
              Wacom青少年专属电磁笔
              <sup onClick={handleClickComment3} style={{ cursor: 'pointer' }}>
                {index3}
              </sup>
            </span>
          </p>
          <p className="bn-info">
            <span>「以练促学」精准学系统</span>
            <span>|</span>
            <span>全场景个性化自主学</span>
          </p>

          <p className="bn-price">
            <span className="bn-price-num">￥3899</span>
            <span className="bn-price-desc">￥4299</span>
          </p>

          <div className="bn-btn-flex" ref={buyButtonRef}>
            <button onClick={onClickJD}>京东官方旗舰店</button>
            <button onClick={onClickTB}>天猫官方旗舰店</button>
          </div>

          <div className="bn-img-wrap">
            <img src={PicImg} alt="小猿学练机" />
          </div>
        </div>
      )}
    </>
  )
}
